@import '../../../../../styles/base/color';
@import '../../../../../styles/base/breakpoints';
@import '../../../../../styles/mixins/mixins';
@import '../../../../../styles/base/typography';

.form-dir-envio{
    @include flexRow(flex-start, flex-start);
    flex-wrap: wrap;
        justify-content: space-between;
    gap: 18px 5px;
    padding: 20px;
    width: 100%;
    position: relative;
    border: 1px solid $boxShadow;
    border-radius: 10px;

    &-extra {
        @include flexRow(flex-start, space-between);
        width: 100%;

        @include lg {
            flex-direction: row;
            flex-wrap: nowrap !important;
            justify-content: space-between;
        }

        @include md {
            flex-wrap: wrap;
        }
    }

    fieldset.MuiOutlinedInput-notchedOutline {
        border: none !important;
        padding: 0 !important;
    }
    
    @include md {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    h4 {
        @include primaryText(16px);
        padding-bottom: 10px;
        width: 100% !important;
    }

    &__div {
        width: 100%; 

        @include lg {
            width: 48.6%;
        }

        span {
            @include flexRow(center, flex-start);
            padding-top: 7px;
            gap: 3px;
            font-size: 13px !important;
            color: $danger;
    
            @include lg {
                font-size: 15px !important;
            }
        }
    }

    &__direccion {
        @include lg {
            width: 100% !important;
        }
    }

    &__cp {
        @include lg {
            width: 25% !important;
        }
    }


    input[type=text], input[type=tel], input[type=email] {
        color: $fontPrimary !important;
        font-weight: 400 !important;
    }

    label {
        color: $fontPrimary !important;
        text-transform: none;
    }

    &__label {
        @include flexCol(flex-start, flex-start);
        gap: 4px;
        width: 100%;
        font-size: 13px !important;

        input, &--select {
            width: 100%;
            background-color: $background !important;
            border: none !important;
            border-radius: 8px;
            height: 40px;
            outline-color: $primary;
            padding: 0 16px;
            font-size: 13px !important;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-background-clip: text;
            box-shadow: inset 0 0 20px 20px $background;
        }
        
        &--select {
            font-weight: 400 !important;
        }
    
    }

    ul { 
        list-style: square; 
        padding-left: 1rem;

        li {
            list-style: square;
            font-size: 13px;
            color: $fontPrimary !important;
        }
    }

    &__intracomunitario {
        @include flexRow(flex-end, flex-end);
        gap: 5px;
        font-size: 14px !important;
        width: 100% !important;
        cursor: pointer;
        input {
            margin-bottom: 3px;
            cursor: pointer;
        }
    }

    &__poblacion {
        width: 100%;

        @include lg {
            width: 48.6%;
        }
    }

    &__error-alert {
        @include flexRow(center, flex-start);
        color: $danger !important;
        gap: 5px;
        font-size: 13px !important;

        @include lg {
            font-size: 15px !important;
        }
        
        span {
            font-size: 14px !important;
            padding: 0 !important;
            @include lg {
                font-size: 15px !important;
            }
        }
    }

    &__button {
        width: 100% !important;
        @include flexRow(flex-end, flex-end);
        
        &--btn {
            border: 1px solid $primary;
            align-self: flex-end;
            justify-self: flex-end;
            height: 36px;
            border-radius: 6px;
            background-color: $white;
            color: $primary;
            font-size: 13px;
            cursor: pointer;
            margin-top: 12px;
            padding: 0 48px;
            font-weight: 500;
            width: 160px;
            position: relative;
            
            &:hover {
                background-color: $primary;
                color: $white;
            }

            &:disabled {
                opacity: 0.8;
                cursor: default;
                background-color: $white !important;
            }

            &:active {
                -webkit-transform: scale(0.98);
                transform: scale(0.98);
            }
        }

        }
    .MuiAlert-standard {
        width: 100% !important;
        font-size: 13px !important;
        @include lg {
            font-size: 12px !important;
        }
        @include custom(1060px) {
            font-size: 13px !important;
        }
    }

}

    .skeleton-input {
        width: 100%;
        height: 40px;
        background-size: 200% 100%;
        border-radius: 8px;
        background: $background;
        overflow: hidden;
        &__span {
            width: 100%;
            height: 40px;
            background: linear-gradient(90deg, $background 25%, $btnFotos 50%, $background 75%);
            animation: loading 2s infinite ease-in-out;
        }
    }

    @keyframes loading {
        0% {
            background-position: 200% 0;
        }
        100% {
            background-position: -200% 0;
        }
    }

.delivery-schedule {
    @include flexCol(flex-start, flex-start);
    width: 100%;
    padding-top: 10px;

    &__container {
        @include flexCol(flex-start, flex-start);
        width: 100%;
        gap: 5px;

        @include md {
            flex-direction: row;
            gap: 32px;
        }

        label {
            @include flexRow(center, flex-start);
            gap: 5px;
            font-size: 14px !important;
            cursor: pointer;

            input {
                accent-color: $primary;
                cursor: pointer;
            }
        }
    }
}

